import type { FC, ReactNode } from 'react'

import React from 'react'
import PropTypes from 'prop-types'
import logger from '@/utils/console'

const log = logger('contexts:settings')

const defaultSettings = (): Settings => {
  return {
    responsiveFontSizes: false,
    theme: 'light'
  }
}

export interface Settings {
  responsiveFontSizes?: boolean
  theme: 'light' | 'dark'
}

export interface SettingsContextValue {
  settings: Settings
  // eslint-disable-next-line no-unused-vars
  saveSettings: (update: Settings) => void
}

interface SettingsProviderProps {
  children?: ReactNode
}

const initialSettings: Settings = {
  responsiveFontSizes: true,
  theme: 'light'
}

export const restoreSettings = (): Settings | null => {
  let settings = defaultSettings()

  try {
    const storedData: string | null =
      globalThis.localStorage.getItem('settings')

    if (storedData) {
      settings = JSON.parse(storedData)
    } else {
      settings = {
        responsiveFontSizes: true,
        theme: globalThis.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
      }
    }
  } catch (err) {
    log.error({ err }, 'Failed to restore settings with ERROR')
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings
}

export const storeSettings = (settings: Settings): void => {
  globalThis.localStorage.setItem('settings', JSON.stringify(settings))
}

export const SettingsContext = React.createContext<SettingsContextValue>({
  settings: initialSettings,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveSettings: () => {}
})

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props
  const [settings, setSettings] = React.useState<Settings>(initialSettings)

  React.useEffect(() => {
    const restoredSettings = restoreSettings()

    if (restoredSettings) {
      saveSettings(restoredSettings)
    }
  }, [])

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings)
    storeSettings(updatedSettings)
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const SettingsConsumer = SettingsContext.Consumer

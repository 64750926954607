import type { SettingsContextValue } from '@/contexts/settings-context'
import type { Session } from '@/types/session'

import React from 'react'
import Router from 'next/router'
import useSWR from 'swr'

import { SettingsContext } from '@/contexts/settings-context'

export const useMounted = () => {
  const isMounted = React.useRef(false)

  React.useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return React.useCallback(() => isMounted.current, [])
}

export function useSession({
  redirectTo = '/login',
  redirectIfFound = false
} = {}) {
  const { data: session, mutate: mutateSession } = useSWR<Session>('/api/auth')

  React.useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !session) {
      return
    }

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !session?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && session?.isLoggedIn)
    ) {
      Router.push(redirectTo)
    }
  }, [session, redirectIfFound, redirectTo])

  return { session, mutateSession }
}

export const useSettings = (): SettingsContextValue =>
  React.useContext(SettingsContext)

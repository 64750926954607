/**
 * Providers Portal constants
 */

export const APP_DISPLAY_NAME = 'Providers Portal'
export const APP_DISPLAY_SLOGAN = ''

export const INST_DISPLAY_NAME = 'Solera National Bank'

// Contact information
export const CUSTOMER_SUPPORT_PHONE_NUMBER = '303-937-6426'
export const CUSTOMER_SUPPORT_EMAIL = 'selfdirected@solerabank.com'

export const CYPRESS_TEST_EMAIL = 'CypressTesting@test.com'
export const CYPRESS_TEST_PASSWORD = 'cypressTestsAreGreat'

/**
 * Backend logging utility for consistently formatted logs
 */

import type { LoggerOptions } from 'pino'

import Pino from 'pino'
import config from '@/configs/app'

const pinoConfig: LoggerOptions = {
  prettifier: null,
  ...config.logger
}

if (config.isDebug) {
  pinoConfig.transport = {
    target: 'pino-pretty',
    options: {
      colorize: true
    }
  }
}

export default Pino(pinoConfig)

/**
 * Application configuration data object
 *
 * All app and component configuration, environment detection, etc
 */

const appId = `${process.env.npm_package_name}@${process.env.npm_package_version}`
const isDev = process.env.APP_ENV !== 'production'
const isDebug = process.env.APP_DEBUG === 'true'

export default {
  appId,
  isDev,
  isDebug,

  appUrl: process.env.APP_URL,
  env: process.env.APP_ENV,
  name: process.env.npm_package_name,
  version: process.env.npm_package_version,

  authentication: {
    expiration: 3600,
    secret: process.env.BLAZE_CREDENTIAL_SECRET
  },

  services: {
    backbone: {
      clientId: process.env.BLAZE_BACKBONE_CLIENT_ID,
      location: process.env.BLAZE_BACKBONE_LOCATION,
      timeout: 15000 // 15 seconds
    }
  },

  logger: {}
}

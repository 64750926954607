/**
 * Utility functions for the local API
 */

import type { NextApiRequest, NextApiResponse } from 'next'

import logger from '@/utils/logger'
import Router from 'next/router'
import { AxiosError } from 'axios'

const log = logger.child({ module: 'api-utils' })

export class FetchError extends Error {
  response: Response
  data: {
    message: string
  }
  constructor({
    message,
    response,
    data
  }: {
    message: string
    response: Response
    data: {
      message: string
    }
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError)
    }

    this.name = 'FetchError'
    this.response = response
    this.data = data ?? { message: message }
  }
}

export async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const response = await fetch(input, init)

  // checks to see if there's a response body.
  // If not, an empty object will be returned
  let data
  try {
    data = await response.json()
  } catch {
    data = {}
  }

  // response.ok is true when res.status is 2xx
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (response.ok) {
    return data
  }

  throw new FetchError({
    message: response.statusText,
    response,
    data
  })
}

export function getRequestIp(req: NextApiRequest): string | null {
  const forwarded = String(req.headers['x-forwarded-for'])
  return forwarded ? forwarded.split(/, /)[0] : req.socket.remoteAddress || null
}

export function onError(
  err: AxiosError,
  req: NextApiRequest,
  res: NextApiResponse
): void {
  log.error({ err, stack: err.stack }, 'API ROUTE ERROR')

  interface ErrorData {
    meta: {
      status: string
      title: string
      detail: string
    }
  }

  const errorData = err?.response?.data as ErrorData
  if (
    errorData.meta.status === '401' &&
    errorData.meta.detail === 'jwt expired'
  ) {
    return res.status(401).json({ message: 'JWT EXPIRED' })
  }

  return res.status(500).send('API ROUTE ERROR')
}

export function onNoMatch(req: NextApiRequest, res: NextApiResponse): void {
  res.status(404).send('Page is not found')
}

export function onSwrError(err: Error): void {
  if (err.message === 'Unauthorized') {
    Router.push('/login')
  }
  log.error({ err, stack: err.stack }, 'SWR ERROR')
}

/**
 * Logging utility for browser-side app.
 *
 * Wrapper for the `console` utility.
 */

import config from '@/configs/app'

const debugModeEnabled = config.isDebug

type LogLevel = 'debug' | 'info' | 'warn' | 'error'

function doOutput(
  level: LogLevel,
  prefix: string,
  data: Record<string, unknown> | null,
  message: string | null,
  // eslint-disable-next-line
  out: (...data: any[]) => void
) {
  if (typeof data === 'string') {
    message = data
    data = null
  }

  const msg = message ? ` ${message}` : ''
  if (data === null) {
    out(`${level}: [${prefix}]${msg}`)
  } else {
    out(`${level}: [${prefix}]${msg}`, { ...data })
  }
}

function debug(prefix: string) {
  return (data: Record<string, unknown>, message: string | null = null) =>
    // eslint-disable-next-line no-console
    debugModeEnabled && doOutput('debug', prefix, data, message, console.log)
}

function info(prefix: string) {
  return (data: Record<string, unknown>, message: string | null = null) =>
    // eslint-disable-next-line no-console
    doOutput('info', prefix, data, message, console.log)
}

function warn(prefix: string) {
  return (data: Record<string, unknown>, message: string | null = null) =>
    // eslint-disable-next-line no-console
    doOutput('warn', prefix, data, message, console.warn)
}

function error(prefix: string) {
  return (data: Record<string, unknown>, message: string | null = null) =>
    // eslint-disable-next-line no-console
    doOutput('error', prefix, data, message, console.error)
}

function logger(prefix: string) {
  return {
    debug: debug(prefix),
    info: info(prefix),
    warn: warn(prefix),
    error: error(prefix)
  }
}

export default logger

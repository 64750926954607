import type { IBlazeAppProps } from '@/types/app-webui'

import React from 'react'
import Head from 'next/head'
import nProgress from 'nprogress'
import { Router, useRouter } from 'next/router'
import { Toaster } from 'react-hot-toast'
import { SWRConfig } from 'swr'
import { Button, CssBaseline } from '@mui/material'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ActivityDetector from 'react-activity-detector'
import toast from 'react-hot-toast'

import { APP_DISPLAY_NAME, INST_DISPLAY_NAME } from '@/configs/constants'
import { SettingsConsumer, SettingsProvider } from '@/contexts/settings-context'
import { createTheme } from '@/themes/theme-builder'
import { fetcher, onSwrError } from '@/utils/api-utils'
import { createEmotionCache } from '@/utils/emotion'
import { sessionOptions } from '@/configs/session'
import { useSession } from '@/utils/hooks'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeComplete', nProgress.done)
Router.events.on('routeChangeError', nProgress.done)

const BlazeApp = (props: IBlazeAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const getLayout = Component.getLayout ?? ((page) => page)
  const router = useRouter()
  const session = useSession()

  const handleLogoutOnIdle = async (): Promise<void> => {
    if (session.session?.isLoggedIn) {
      try {
        await fetcher('/api/auth', { method: 'DELETE' })
        router.push('/login')
        toast(
          (t) => (
            <span>
              <div>
                For security, you have been logged out due to inactivity{' '}
                <Button
                  variant='text'
                  onClick={() => toast.dismiss(t.id)}
                  size='small'
                >
                  Dismiss
                </Button>
              </div>
            </span>
          ),
          {
            id: 'inactivity',
            duration: Infinity
          }
        )
      } catch (err) {
        toast.error('Unable to logout.')
      }
    }
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <title>{`${APP_DISPLAY_NAME} | ${INST_DISPLAY_NAME}`}</title>
      </Head>
      <ActivityDetector
        enabled={true}
        timeout={
          (Number(process.env.NEXT_PUBLIC_COOKIE_MAX_AGE_SECONDS) || 600) * 1000 // Default to 10 minutes if it is not set.
        }
        onIdle={handleLogoutOnIdle}
      />
      <SWRConfig value={{ fetcher, onError: onSwrError }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    responsiveFontSizes: settings.responsiveFontSizes,
                    mode: 'light'
                  })}
                >
                  {/*
                    CssBaseline kickstart an elegant, consistent,
                    and simple baseline to build upon.
                  */}
                  <CssBaseline />
                  <Toaster position='top-center' />

                  {getLayout(<Component {...pageProps} />)}
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </LocalizationProvider>
      </SWRConfig>
    </CacheProvider>
  )
}

export default BlazeApp
